<template>
  <transition name="header-modal-fade">
    <div class="header-modal">
      <slot name="top" />
      <div class="header-modal__content">
        <div class="header-modal__header">
          <div class="container-fluid px-0">
            <div class="row no-gutters">
              <div
                :class="[
                  'col-22 d-flex align-items-center',
                  {
                    'order-2': cancelPosition == 'left',
                  },
                ]"
              >
                <slot name="header" />
              </div>
              <div
                :class="[
                  'col-2 d-flex align-items-center justify-content-start',
                  {
                    'order-1': cancelPosition == 'left',
                  },
                  {
                    'justify-content-end': cancelPosition == 'right',
                  },
                ]"
              >
                <button class="header-modal__cancel" @click.stop="closeModal">
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="header-modal__body"
          :style="{
            marginTop: marginTop + 'px',
            marginBottom: marginBottom + 'px',
          }"
        >
          <slot />
        </div>
        <div class="header-modal__bottom">
          <slot name="bottom" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    marginTop: {
      type: Number,
      default: null,
    },
    marginBottom: {
      type: Number,
      default: null,
    },
    cancelPosition: {
      type: String,
      default: 'right',
      validator: val => ['right', 'left'].includes(val),
    },
  },
  methods: {
    closeModal(evt) {
      let isContainer = evt.target.classList.contains('header-modal')
      let isCancel = evt.target.classList.contains('header-modal__cancel')

      if (isContainer || isCancel) {
        this.$emit('click')
      }
    },
  },
}
</script>
