var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'vl-input-text vl-wrapper',
    {
      'on-color-bg': _vm.onColorBg,
      'light-mode': _vm.lightMode,
    },
  ]},[_c('fieldset',{class:[
      'vl-title',
      {
        'vl-title--active': _vm.isActive,
        'vl-title--filled': _vm.isFilled,
        'vl-title--placeholder': _vm.isPlaceholder,
        'vl-title--disabled': _vm.disabled,
        'vl-title--error': _vm.error,
      },
    ],attrs:{"aria-hidden":"true"}},[_c('legend',{ref:"legend"},[_c('span',[_vm._v(" ")])])]),_vm._v(" "),_c('div',{staticClass:"vl-input-wrapper"},[_c('input',{ref:"input",class:[{ 'vl-input-password': _vm.type === 'password' && _vm.showEye }, 'vl-input'],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"name":_vm.name,"autocomplete":_vm.autocomplete,"type":_vm.inputType},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":_vm.onChangeValue,"blur":_vm.blur,"focus":_vm.focus,"keypress":_vm.keypress}}),_vm._v(" "),_c('label',{class:[
        'vl-label',
        {
          'vl-label--active-label': _vm.isActiveLabel,
        },
      ]},[_c('span',{ref:"label",class:{
          'vl-label--active': _vm.isActive,
          'vl-label--filled': _vm.isFilled,
          'vl-label--placeholder': _vm.isPlaceholder,
          'vl-label--disabled': _vm.disabled,
          'vl-label--error': _vm.error,
        }},[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")])]),_vm._v(" "),(_vm.$slots.icon && _vm.type !== 'password')?_c('div',{staticClass:"icon"},[_vm._t("icon")],2):(_vm.type === 'password' && _vm.showEye)?_c('div',{staticClass:"icon-eye",on:{"click":function($event){$event.preventDefault();return _vm.toggleShowPassword.apply(null, arguments)}}},[(_vm.showPassword)?_c('vl-icon-eye-slash',{attrs:{"colorFill":_vm.colorFill}}):_c('vl-icon-eye',{attrs:{"colorFill":_vm.colorFill}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }