// import { ORDER_SHOW } from '@javascript/config/routes'
// const ROUTES = [ORDER_SHOW]
const ROUTES = []
const addBaseURLToRoute = (baseUrl, routes) => {
  return routes.map(item => {
    return baseUrl + item
  })
}
export default {
  methods: {
    disableOnPage(baseUrl) {
      const href = window.location.href
      const routesWithLocale = addBaseURLToRoute(baseUrl, ROUTES)
      if (routesWithLocale.includes(href)) {
        return false
      }
      return true
    },
  },
}
