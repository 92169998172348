<template>
  <div class="header-top">
    <div class="container h-100 px-0">
      <div class="row no-gutters justify-content-between h-100">
        <div class="col-24 h-100" v-if="isHideOnThisPage">
          <div
            class="d-none d-md-flex align-items-start flex-column justify-content-center h-100"
          >
            <language-container
              v-if="this.disableOnPage(this.baseUrlWithLocale)"
              :available-locales="availableLocales"
              :current-path="currentPath"
              :locale="locale"
              :baseUrlWithLocale="baseUrlWithLocale"
            />
          </div>
          <div class="d-md-none h-100">
            <mobile-language-container
              v-if="this.disableOnPage(this.baseUrlWithLocale)"
              :available-locales="availableLocales"
              :current-path="currentPath"
              :locale="locale"
              :baseUrlWithLocale="baseUrlWithLocale"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LanguageContainer from './language-container'
import MobileLanguageContainer from './language-container/MobileLanguageContainer'
import locales from './locales'
import { ORDER_SHOW } from '@javascript/config/routes'
import disableLanguageContainer from '@javascript/mixins/disableLanguageContainer'

export default {
  name: 'HeaderTop',
  components: {
    LanguageContainer,
    MobileLanguageContainer,
  },
  mixins: [disableLanguageContainer],
  props: {
    availableLocales: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    t: locales,
  }),
  computed: {
    isOrderPage() {
      const orderRouter = `${this.baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    isVerifyEmailPage() {
      const path = window.location.pathname
      const regex = new RegExp('/(registration/verify_welcome)/')

      return path.match(regex)
    },
    isHideOnThisPage() {
      return !this.isOrderPage && !this.isVerifyEmailPage
    },
  },
}
</script>
