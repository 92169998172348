<template>
  <div
    :class="[
      'language-container',
      {
        'language-container--row': row,
      },
    ]"
  >
    <div v-if="!row" class="language-container__placeholder">{{ currentLanguageTitle }}</div>
    <ul class="language-container__list language-list">
      <li
        :class="[
          'language-list__item',
          {
            'is-current': checkCurrentLocale(language.key),
          },
        ]"
        v-for="language in languageList"
        :key="language.key"
      >
        <a class="language-list__item-link" :href="path(language.key)">{{ language.title }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'LanguageContainer',
  props: {
    row: {
      type: Boolean,
      default: false,
    },
    availableLocales: {
      type: String,
      default: null,
    },
    currentPath: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    availableLocalesData: null,
    currentPathData: null,
  }),
  computed: {
    currentLanguageTitle() {
      return this.availableLocalesData[this.locale]
    },
    languageList() {
      return Object.entries(this.availableLocalesData).map(item => ({
        key: item[0],
        title: item[1],
      }))
    },
  },
  methods: {
    path(locale) {
      return this.currentPathData.find(item => item.locale === locale).path
    },
    checkCurrentLocale(locale) {
      return this.locale === locale
    },
  },
  created() {
    this.availableLocalesData = JSON.parse(this.availableLocales)
    this.currentPathData = JSON.parse(this.currentPath)
  },
}
</script>
