<template>
  <div class="popap-container">
    <div class="popap-container__arrow-up"></div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Popap',
  methods: {
    handleClickOutside(event) {
      const target = event.target
      const excludePopap = document.querySelector('.popap-container')
      const excludeHeaderBtn = document.querySelector('.header__login-button')
      const excludeNotivication = document.querySelector('.vl-notification')
      const excludePopapCondition =
        target === excludePopap || excludePopap.contains(target)
      const excludeHeaderBtnCondition =
        target === excludeHeaderBtn || excludeHeaderBtn.contains(target)
      const excludeNotivicationCondition =
        target === excludeNotivication || excludeNotivication.contains(target)

      if (
        excludePopapCondition ||
        excludeHeaderBtnCondition ||
        excludeNotivicationCondition
      ) {
        return
      }
      this.$emit('clickOutside')
    },
  },
  created() {
    document.body.addEventListener('click', this.handleClickOutside, true)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleClickOutside, true)
  },
}
</script>
