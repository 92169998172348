<template>
  <header class="header">
    <header-top
      :available-locales="availableLocales"
      :current-path="currentPath"
      :locale="locale"
      :default-locale="defaultLocale"
      :base-url-with-locale="baseUrlWithLocale"
      @toggleMainMenu="handleMainMenuStatus"
    />
    <header-middle
      :available-locales="availableLocales"
      :current-path="currentPath"
      :is-login="Boolean(isLogin)"
      :user-name="userName"
      :profile-menu="sortedProfileMenu"
      :is-show-mobile-menu="isShowMobileMenu"
      :is-show-main-menu="isShowMainMenu"
      :locale="locale"
      :default-locale="defaultLocale"
      :base-url-with-locale="baseUrlWithLocale"
      @click="handleMobileMenuStatus"
      @toggleMainMenu="handleMainMenuStatus"
    />
    <header-bottom
      :is-login="Boolean(isLogin)"
      :user-data="this.userData"
      :user-name="userName"
      :profile-menu="sortedProfileMenu"
      :provider-id="providerId"
      :locale="locale"
      :default-locale="defaultLocale"
      :base-url-with-locale="baseUrlWithLocale"
      @click="handleMobileMenuStatus"
    />
  </header>
</template>

<script>
import HeaderTop from './HeaderTop'
import HeaderMiddle from './HeaderMiddle'
import HeaderBottom from './HeaderBottom'

export default {
  name: 'Header',
  components: {
    HeaderTop,
    HeaderMiddle,
    HeaderBottom,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    availableLocales: {
      type: String,
      default: '',
    },
    currentUser: {
      type: String,
      default: '',
    },
    session: {
      type: String,
      default: null,
    },
    headerMenu: {
      type: String,
      default: '',
    },
    profileMenu: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    providerId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    isShowMobileMenu: false,
    isShowMainMenu: false,
    userData: null,
    headerMenuData: null,
    profileMenuData: null,
  }),
  computed: {
    isLogin() {
      return JSON.parse(this.session)
    },
    userName() {
      return this.userData ? this.userData.first_name : ''
    },
    sortedHeaderMenu() {
      return this.headerMenuData
        ? this.headerMenuData.children.sort((prev, next) => {
            return prev.position - next.position
          })
        : []
    },
    sortedProfileMenu() {
      return this.profileMenuData
        ? this.profileMenuData.children.sort((prev, next) => {
            return prev.position - next.position
          })
        : []
    },
  },
  methods: {
    handleMobileMenuStatus(value) {
      this.isShowMobileMenu = value
    },
    handleMainMenuStatus(value) {
      this.isShowMainMenu = value
    },
  },
  created() {
    this.userData = JSON.parse(this.currentUser)
    this.headerMenuData = this.headerMenu && JSON.parse(this.headerMenu)
    this.profileMenuData = this.profileMenu && JSON.parse(this.profileMenu)
  },
}
</script>
