<template>
  <div
    :class="[
      'vl-input-text vl-wrapper',
      {
        'on-color-bg': onColorBg,
        'light-mode': lightMode,
      },
    ]"
  >
    <fieldset
      :class="[
        'vl-title',
        {
          'vl-title--active': isActive,
          'vl-title--filled': isFilled,
          'vl-title--placeholder': isPlaceholder,
          'vl-title--disabled': disabled,
          'vl-title--error': error,
        },
      ]"
      aria-hidden="true"
    >
      <legend ref="legend">
        <span>&#160;</span>
      </legend>
    </fieldset>
    <div class="vl-input-wrapper">
      <input
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="name"
        :autocomplete="autocomplete"
        :class="[{ 'vl-input-password': type === 'password' && showEye }, 'vl-input']"
        :type="inputType"
        ref="input"
        @input="$emit('input', $event.target.value)"
        @change="onChangeValue"
        @blur="blur"
        @focus="focus"
        @keypress="keypress"
      />
      <label
        :class="[
          'vl-label',
          {
            'vl-label--active-label': isActiveLabel,
          },
        ]"
      >
        <span
          :class="{
            'vl-label--active': isActive,
            'vl-label--filled': isFilled,
            'vl-label--placeholder': isPlaceholder,
            'vl-label--disabled': disabled,
            'vl-label--error': error,
          }"
          ref="label"
        >
          {{ label }}
        </span>
      </label>
      <div v-if="$slots.icon && type !== 'password'" class="icon">
        <slot name="icon" />
      </div>
      <div v-else-if="type === 'password' && showEye" class="icon-eye" @click.prevent="toggleShowPassword">
        <vl-icon-eye-slash :colorFill="colorFill" v-if="showPassword" /> <vl-icon-eye :colorFill="colorFill" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import fieldsetV2 from '@javascript/mixins/fieldsetV2'
import VlIconEye from '@components/@exim/ui/vl-icons/VlIconEye'
import VlIconEyeSlash from '@components/@exim/ui/vl-icons/VlIconEyeSlash'
export default {
  name: 'VlInputText',
  components: {
    VlIconEye,
    VlIconEyeSlash,
  },
  mixins: [fieldsetV2],
  props: {
    value: {
      type: String,
      default: null,
    },
    colorFill: {
      type: String,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: val =>
        ['text', 'tel', 'email', 'password', 'hidden'].includes(val),
    },
    label: {
      type: String,
      default: 'Input text',
    },
    error: {
      type: Boolean,
      default: false,
    },
    onColorBg: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    lightMode: {
      type: Boolean,
      default: false,
    },
    showIconError: {
      type: Boolean,
      default: false,
    },
    showEye: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPassword: false,
  }),
  methods: {
    onChangeValue(evt) {
      this.$emit('change', evt.target.value)
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    keypress(evt) {
      this.$emit('keypress', evt)
    },
  },
  computed: {
    inputType() {
      return this.showPassword ? 'text' : this.type
    }
  },
}
</script>
