var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"header-modal-fade"}},[_c('div',{staticClass:"header-modal"},[_vm._t("top"),_vm._v(" "),_c('div',{staticClass:"header-modal__content"},[_c('div',{staticClass:"header-modal__header"},[_c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{class:[
                'col-22 d-flex align-items-center',
                {
                  'order-2': _vm.cancelPosition == 'left',
                },
              ]},[_vm._t("header")],2),_vm._v(" "),_c('div',{class:[
                'col-2 d-flex align-items-center justify-content-start',
                {
                  'order-1': _vm.cancelPosition == 'left',
                },
                {
                  'justify-content-end': _vm.cancelPosition == 'right',
                },
              ]},[_c('button',{staticClass:"header-modal__cancel",on:{"click":function($event){$event.stopPropagation();return _vm.closeModal.apply(null, arguments)}}},[_c('span')])])])])]),_vm._v(" "),_c('div',{staticClass:"header-modal__body",style:({
          marginTop: _vm.marginTop + 'px',
          marginBottom: _vm.marginBottom + 'px',
        })},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"header-modal__bottom"},[_vm._t("bottom")],2)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }