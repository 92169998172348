<template>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 5C1 2.79086 2.79086 1 5 1H27C29.2091 1 31 2.79086 31 5V26.7945C31 29.0037 29.2091 30.7945 27 30.7945H5C2.79086 30.7945 1 29.0037 1 26.7945V5Z"
      :stroke="setColorToStroke"
      :stroke-width="setStrokeWidth"
    />
    <rect
      x="9"
      y="14.9041"
      width="14"
      height="1.9863"
      rx="0.993151"
      :fill="setColorToFill"
    />
  </g>
</template>
<script>
const DEFAULT_STROKE_WIDTH = '2'

export default {
  name: 'VlIconMinus',
  props: {
    fill: {
      type: [String, Array],
      default: '#fff',
    },
    stroke: {
      type: [String, Array],
      default: '#fff',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setColorToFill() {
      if (typeof this.fill === 'string') {
        return this.fill
      }
      return this.fill[0]
    },
    setColorToStroke() {
      if (typeof this.stroke === 'string') {
        return this.stroke
      }
      return this.stroke[0]
    },
    setStrokeWidth() {
      return this.disabled ? '0' : DEFAULT_STROKE_WIDTH
    },
  },
}
</script>
