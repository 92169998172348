<template>
  <div class="basket-mobile" @click="toggleModalCard(true)">
    <span class="basket__link">
      <i class="basket__icon d-md-none">
        <vl-icon-base width="23" height="22" viewBoxWidth="33" viewBoxHeight="32" iconColor="none">
          <vl-icon-basket />
        </vl-icon-base>
      </i>
      <div class="basket__count">{{ cartTotalCount }}</div>
    </span>
    <modal @click="toggleModalCard(false)" v-if="showCard" :margin-top="0" cancel-position="right">
      <div class="wrapper-cart">
        <cart
          :locale="locale"
          :defaultLocale="defaultLocale"
          :userId="userId"
          :base-url-with-locale="baseUrlWithLocale"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Cart from '@components/@exim/cart'
import VlIconBase from '@components/@exim/ui/vl-icons'
import VlIconBasket from '@components/@exim/ui/vl-icons/VlIconBasket'
import Modal from '../modal'

export default {
  name: 'CartMobile',
  components: {
    Modal,
    Cart,
    VlIconBase,
    VlIconBasket,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    userId: {
      type: [String, Number],
      default: '',
    },
    cartTotalCount: {
      type: [String, Number],
      default: 0,
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCard: false,
    }
  },
  methods: {
    toggleModalCard(value) {
      this.showCard = value
    },
  },
}
</script>
