var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'language-container',
    {
      'language-container--row': _vm.row,
    },
  ]},[(!_vm.row)?_c('div',{staticClass:"language-container__placeholder"},[_vm._v(_vm._s(_vm.currentLanguageTitle))]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"language-container__list language-list"},_vm._l((_vm.languageList),function(language){return _c('li',{key:language.key,class:[
        'language-list__item',
        {
          'is-current': _vm.checkCurrentLocale(language.key),
        },
      ]},[_c('a',{staticClass:"language-list__item-link",attrs:{"href":_vm.path(language.key)}},[_vm._v(_vm._s(language.title))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }