<template>
  <div class="cart-item">
    <div class="cart-item__header">
      <div class="cart-item__title">{{ product.title }}</div>
      <vl-button
        class="cart-item__remove-action d-flex"
        type="inline"
        size="inline"
        @click.native="removeProductFromCart(product)"
      >
        <vl-icon-base
          width="16"
          height="17"
          viewBoxWidth="16"
          viewBoxHeight="17"
          iconColor="white"
          opacity="0.5"
        >
          <vl-icon-remove />
        </vl-icon-base>
      </vl-button>
    </div>
    <div class="cart-item__active-block">
      <div class="cart-item__calc w-100">
        <vl-button
          type="inline"
          size="inline"
          :disabled="buttonRemoveDisabled"
          @click.native="decrementProductHandler(product)"
        >
          <vl-icon-base
            iconColor="transparent"
            width="32"
            height="33"
            viewBoxWidth="32"
            viewBoxHeight="33"
          >
            <vl-icon-minus
              :disabled="buttonRemoveDisabled"
              :fill="[buttonRemoveDisabled ? '#fff' : '#d3d5d8']"
              :stroke="[buttonRemoveDisabled ? '#d3d5d8' : '#d3d5d8']"
            />
          </vl-icon-base>
        </vl-button>
        <input
          type="text"
          class="cart-item__calc-input"
          readonly
          :value="product.quantity"
        />
        <vl-button
          type="inline"
          size="inline"
          :disabled="buttonAddDisabled"
          @click.native="addProductHandler(product)"
        >
          <vl-icon-base
            iconColor="transparent"
            width="32"
            height="33"
            viewBoxWidth="32"
            viewBoxHeight="33"
          >
            <vl-icon-plus
              :disabled="buttonAddDisabled"
              :fill="[buttonAddDisabled ? '#fff' : '#d3d5d8']"
              :stroke="[buttonAddDisabled ? '#d3d5d8' : '#d3d5d8']"
            />
          </vl-icon-base>
        </vl-button>
        <div class="cart-item__cost ml-auto">
          {{ product.sitePrice }}
          <span class="cart-item__site-currency">{{
            product.siteCurrency
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import VlButton from '@components/@exim/ui/vl-button'
import VlIconBase from '@components/@exim/ui/vl-icons'
import VlIconRemove from '@components/@exim/ui/vl-icons/VlIconRemove'
import VlIconPlus from '@components/@exim/ui/vl-icons/VlIconPlus'
import VlIconMinus from '@components/@exim/ui/vl-icons/VlIconMinus'

export default {
  name: 'CartItem',
  components: {
    VlButton,
    VlIconBase,
    VlIconRemove,
    VlIconPlus,
    VlIconMinus,
  },
  props: {
    product: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    minimumOrderQuantity: 1,
  }),
  computed: {
    buttonRemoveDisabled() {
      return this.product.quantity <= this.minimumOrderQuantity
    },
    buttonAddDisabled() {
      return this.product.quantity >= this.product.stockCount
    },
  },
  methods: {
    ...mapActions({
      addProductToCart: 'cart/addProductToCart',
      removeProductFromCart: 'cart/removeProductFromCart',
    }),
    ...mapMutations({
      decrementItemQuantity: 'cart/decrementItemQuantity',
    }),
    decrementProductHandler(product) {
      if (!this.buttonRemoveDisabled) {
        this.decrementItemQuantity(product)
      }
    },
    addProductHandler(product) {
      if (!this.buttonAddDisabled) {
        this.addProductToCart(product)
      }
    },
  },
}
</script>
