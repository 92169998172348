<template>
  <div class="basket-desktop">
    <span class="basket__link">
      <i class="basket__icon">
        <vl-icon-base width="37" height="33" viewBoxWidth="37" viewBoxHeight="33" iconColor="none">
          <vl-icon-basket />
        </vl-icon-base>
      </i>
      <div class="basket__count">{{ cartTotalCount }}</div>
    </span>
    <div class="basket__list-item">
      <cart
        :locale="locale"
        :defaultLocale="defaultLocale"
        :userId="userId"
        :base-url-with-locale="baseUrlWithLocale"
      />
    </div>
  </div>
</template>

<script>
import Cart from '@components/@exim/cart'
import VlIconBase from '@components/@exim/ui/vl-icons'
import VlIconBasket from '@components/@exim/ui/vl-icons/VlIconBasket'

export default {
  name: 'CartDesktop',
  components: {
    Cart,
    VlIconBase,
    VlIconBasket,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    userId: {
      type: [String, Number],
      default: '',
    },
    cartTotalCount: {
      type: [String, Number],
      default: 0,
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
}
</script>
