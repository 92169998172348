<script>
import AppHeader from '@components/@exim/header'
import AppFooter from '@components/@exim/footer'
import ProductSlider from '@components/product-slider'
import BubbleAnimation from '@components/@exim/bubble-animation'
import NotificationsList from '@components/@exim/ui/vl-notification'
import setValidateMessages from '@javascript/plugins/localization/veeValidateMessage'

export default {
  name: 'App',
  provide: {
    locale: 'uk',
    defaultLocale: 'uk',
    baseUrlWithLocale: '',
    isGuest: true,
  },
  components: {
    AppHeader,
    AppFooter,
    BubbleAnimation,
    NotificationsList,
    ProductSlider,
    PersonalProfile: () =>
      import('@javascript/pages/profile/personal-profile/exim/PersonalProfile'),
    PersonalProfileEdit: () =>
      import(
        '@javascript/pages/profile/personal-profile-edit/exim/PersonalProfileEdit'
      ),
    PersonalMyBalance: () =>
      import(
        '@javascript/pages/profile/personal-my-balance/exim/PersonalMyBalance'
      ),
    RegistrationDetails: () =>
      import('@javascript/pages/registration/details/exim/RegistrationDetails'),
    PersonalCards: () =>
      import('@javascript/pages/profile/personal-cards/exim/PersonalCards'),
    PersonalAddCard: () =>
      import(
        '@javascript/pages/profile/personal-add-card/exim/PersonalAddCard'
      ),
    PersonalOrders: () =>
      import(
        '@javascript/pages/profile/personal-my-orders/exim/PersonalMyOrders'
      ),
    AuthorizationLogin: () =>
      import('@javascript/pages/authorization/login/exim/Login'),
    PersonalChangePassword: () =>
      import(
        '@javascript/pages/profile/personal-change-password/exim/PersonalChangePassword'
      ),
    ForgotPasswordOption: () =>
      import(
        '@javascript/pages/restoration/forgot-password/reset-options/exim/ForgotPasswordOptions'
      ),
    VerificationCode: () =>
      import(
        '@javascript/pages/restoration/forgot-password/verification-code/exim/VerificationCode'
      ),
    CreateNewPassword: () =>
      import(
        '@javascript/pages/restoration/forgot-password/new-password/exim/NewPassword'
      ),
    EmailVerifying: () =>
      import(
        '@javascript/pages/layouts/exim/exim-email-verifying/EmailVerifying'
      ),
    OrderSuccess: () =>
      import('@javascript/pages/order/success/exim/OrderSuccess'),
    OrderPage: () => import('@javascript/pages/order/show/exim/Order'),
    CatalogPage: () => import('@javascript/pages/catalog/exim/Catalog'),
    ProductPage: () => import('@javascript/pages/product/exim/ProductPage'),
    ProductAddress: () =>
      import('@javascript/pages/product-address/exim/ProductAddress'),
    PersonalMyWishList: () =>
      import(
        '@javascript/pages/profile/personal-my-wish-list/exim/PersonalMyWishList'
      ),
    PersonalTaxEdit: () =>
      import(
        '@javascript/pages/profile/personal-tax-edit/exim/PersonalTaxEdit'
      ),
    RefillMobile: () =>
      import('@javascript/pages/portmone/refill-mobile/exim/RefillMobile'),
    PersonalChangeEmail: () =>
      import(
        '@javascript/pages/profile/personal-change-email/exim/PersonalChangeEmail'
      ),
    UpdateProgramInformationPopap: () =>
      import('@components/@exim/update-program-information-popap'),
    SessionPopup: () =>
      import('@javascript/pages/layouts/exim/session-popup/SessionPopup'),
    CashBack: () => import('@javascript/pages/cashback/exim/CashBack'),
    CashBackShow: () => import('@javascript/pages/cashback/exim/CashBackShow'),
    VlPopup: () => import('@javascript/pages/profile/personal-profile/popup'),
  },
  methods: {
    setLocalesSeting() {
      this._provided.locale = this.$el.attributes.locale.value
      this._provided.defaultLocale = this.$el.attributes.defaultLocale.value
      this._provided.baseUrlWithLocale =
        this.$el.attributes.base_url_with_locale.value
      this._provided.isGuest = !JSON.parse(this.$el.attributes.is_guest.value)
      setValidateMessages(this._provided.locale)
    },
  },
  mounted() {
    this.setLocalesSeting()
  },
}
</script>
