<template>
  <div class="header-middle d-lg-none d-md-none">
    <modal v-if="isShowMobileMenu" @click="toggleMobileMenu">
      <template v-slot:top>
        <mobile-language-container
          v-if="isHideOnThisPage"
          :available-locales="availableLocales"
          :current-path="currentPath"
          :locale="locale"
          :baseUrlWithLocale="baseUrlWithLocale"
        />
      </template>
      <template v-slot:header>
        <i class="icon-avatar">
          <vl-icon-base
            iconName="avatar"
            width="29"
            height="29"
            viewBoxWidth="29"
            viewBoxHeight="29"
          >
            <vl-icon-avatar />
          </vl-icon-base>
        </i>
        <span class="header__user-greeting">
          <template v-if="isLogin"
            >{{ t[locale]['header.greeting'] }}, {{ userName }}</template
          >
          <template v-else>{{ t[locale]['header.signin'] }}</template>
        </span>
      </template>
      <template v-if="isLogin">
        <ul class="header-context-menu">
          <li
            v-for="item of profileMenu"
            :key="item.id"
            class="header-context-menu__item"
          >
            <a class="header-context-menu__url" :href="item.url">{{
              item.title
            }}</a>
          </li>
          <li class="header-context-meni__item">
            <a
              class="header-context-menu__url"
              :href="authorizationLoginRouter"
              data-method="delete"
              >{{ t[locale]['authorization.logout.exit'] }}</a
            >
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="header__login-form">
          <login-form
            :providerId="providerId"
            :locale="locale"
            :defaultLocale="defaultLocale"
          />
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import VlIconBase from '@components/@exim/ui/vl-icons'
import VlIconAvatar from '@components/@exim/ui/vl-icons/VlIconAvatar'
import { ORDER_SHOW, AUTHORIZATION_LOGOUT } from '@javascript/config/routes'
import MobileLanguageContainer from './language-container/MobileLanguageContainer'
import loginForm from './login-form'
import Modal from './modal'
import locales from './locales'
export default {
  name: 'HeaderMiddle',
  components: {
    VlIconBase,
    VlIconAvatar,
    Modal,
    MobileLanguageContainer,
    loginForm,
  },
  props: {
    availableLocales: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    isShowMobileMenu: {
      type: Boolean,
      default: false,
    },
    isShowMainMenu: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    providerId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    t: locales,
  }),
  computed: {
    isOrderPage() {
      const orderRouter = `${this.baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    isVerifyEmailPage() {
      const path = window.location.pathname
      const regex = new RegExp('/(registration/verify_welcome)/')

      return path.match(regex)
    },
    isHideOnThisPage() {
      return !this.isOrderPage && !this.isVerifyEmailPage
    },
    authorizationLoginRouter() {
      return `${this.baseUrlWithLocale}${AUTHORIZATION_LOGOUT}`
    },
  },
  methods: {
    toggleMobileMenu() {
      this.$emit('click', !this.isShowMobileMenu)
    },
  },
}
</script>
