<template>
  <ul class="footer-navigation">
    <li
      class="footer-navigation__item"
      v-for="item in footerMenu.children"
      :key="item.id"
    >
      <a
        class="footer-navigation__action-link"
        :href="item.url"
        target="_blank" rel="noopener noreferrer"
        >{{ item.title }}</a
      >
    </li>
  </ul>
</template>
<script>
import locales from './locales'
export default {
  name: 'FooterNavigation',
  inject: ['locale'],
  props: {
    footerMenu: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    t: locales,
  }),

  computed: {
    linkMorePayments() {
      return `${this.t[locale]['footer.eximb_more_payments_more_gifts']}`
    }
  }
}
</script>
