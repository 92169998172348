<template>
  <div class="bubble-animation-wrapper" ref="wrapper">
    <canvas ref="canvas"></canvas>
  </div>
</template>
<script>
let requestAnimFrame = (function(callback) {
  'use strict'
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60)
    }
  )
})()

export default {
  name: 'BubbleAnimation',
  data: () => ({
    circles: [],
    canvas: null,
    context: null,
    colors: ['#f0eaf5', '#C3C3C3', '#f0eaf5', '#dfd2ff'],
    colorsGradient: ['#f0eaf5', '#f0eaf5'],
    minSize: 7,
    maxSize: 30,
    numCircles: 10,
    minSpeed: -2,
    maxSpeed: 2,
    countStroke: 3,
    lineWidth: 4,
  }),
  methods: {
    setCanvasAndContext() {
      this.canvas = this.$refs.canvas
      this.context = this.canvas.getContext('2d')
    },
    buildBybbleArray() {
      'use strict'
      for (let i = 0; i < this.numCircles; i++) {
        let circle = this.getBubbleObject(i)
        this.circles.push(circle)
      }
    },
    getBubbleObject(index) {
      let isStroke = index < this.countStroke,
        color = isStroke
          ? this.colorsGradient
          : Math.floor(Math.random() * this.colors.length),
        left = Math.floor(Math.random() * this.canvas.width),
        top = Math.floor(Math.random() * this.canvas.height),
        size = Math.floor(
          Math.random() * (this.maxSize - this.minSize) + this.minSize
        ),
        leftSpeed =
          Math.floor(
            Math.random() * (this.maxSpeed - this.minSpeed) + this.minSpeed
          ) / 10,
        topSpeed =
          Math.floor(
            Math.random() * (this.maxSpeed - this.minSpeed) + this.minSpeed
          ) / 10
      return {
        color: color,
        left: left,
        top: top,
        size: size,
        leftSpeed: leftSpeed || 0.2,
        topSpeed: topSpeed || 0.2,
        isStroke: isStroke,
      }
    },
    getPositionBubble(circle) {
      let curCircle = JSON.parse(JSON.stringify(circle)),
        gradient = null,
        positionRight = curCircle.left + curCircle.size,
        positionLeft = curCircle.left - curCircle.size,
        positionBottom = curCircle.top + curCircle.size,
        positionTop = curCircle.top - curCircle.size

      if (positionRight >= this.canvas.width || positionLeft <= 0) {
        curCircle.leftSpeed *= -1
      }
      curCircle.left = curCircle.left + curCircle.leftSpeed

      if (positionBottom >= this.canvas.height || positionTop <= 0) {
        curCircle.topSpeed *= -1
      }
      curCircle.top = curCircle.top + curCircle.topSpeed
      return curCircle
    },
    setARC(left, top, size) {
      this.context.arc(left, top, size, 0, 2 * Math.PI, false)
    },
    getGradient(left, top, size) {
      return this.context.createLinearGradient(
        left,
        top,
        left + size,
        top + size
      )
    },
    drawBubble(isStroke, color, gradient) {
      if (isStroke) {
        gradient.addColorStop('0', color[0])
        gradient.addColorStop('1', color[1])
        this.context.strokeStyle = gradient
        this.context.stroke()
      } else {
        this.context.fillStyle = this.colors[color - 1]
        this.context.fill()
      }
      this.context.beginPath()
    },
    stepAnimation() {
      'use strict'
      for (let i = 0; i < this.circles.length; i++) {
        this.context.lineWidth = this.lineWidth
        let curCircle = this.getPositionBubble(this.circles[i])
        this.setARC(curCircle.left, curCircle.top, curCircle.size)
        let gradient = this.getGradient(
          curCircle.left,
          curCircle.top,
          curCircle.size
        )
        this.circles[i] = curCircle
        this.drawBubble(curCircle.isStroke, curCircle.color, gradient)
      }
    },
    clearAnimation() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    },
    setSizesCanvas() {
      this.canvas.width = this.$refs.wrapper.offsetWidth
      this.canvas.height = this.$refs.wrapper.offsetHeight
    },
    animate() {
      'use strict'
      this.clearAnimation()
      this.stepAnimation()
      requestAnimFrame(() => {
        this.animate()
      })
    },
    addEventWindow() {
      window.onresize = () => {
        this.setSizesCanvas()
      }
    },
    startAnimation() {
      this.setSizesCanvas()
      this.buildBybbleArray()
      this.animate()
    },
    setStartParameters() {
      this.$nextTick(() => {
        this.setCanvasAndContext()
        this.startAnimation()
        this.addEventWindow()
      })
    },
  },
  mounted() {
    this.setStartParameters()
  },
}
</script>
