<template>
  <div
    :class="[showLanguageList && 'active', 'mobile-language-container mobile-language-container--row']"
  >
    <div v-click-outside="clickedOutside">
      <div
        @click="toggleLanguagesList(!showLanguageList)"
        class="mobile-language-container__placeholder"
      >{{ currentLanguageTitle }}</div>
      <div class="mobile-language-container__list">
        <div
          v-for="language in languageList"
          :key="language.key"
          :class="[
          'mobile-language-container__list__item',
          {
            'is-current': checkCurrentLocale(language.key),
          },
        ]"
        >
          <a
            class="mobile-language-container__list__item-link"
            :href="path(language.key)"
          >{{ language.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LanguageContainer',
  props: {
    row: {
      type: Boolean,
      default: false,
    },
    availableLocales: {
      type: String,
      default: null,
    },
    currentPath: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    availableLocalesData: null,
    currentPathData: null,
    showLanguageList: false,
  }),
  computed: {
    currentLanguageTitle() {
      return this.availableLocalesData[this.locale]
    },
    languageList() {
      return Object.entries(this.availableLocalesData).map(item => ({
        key: item[0],
        title: item[1],
      }))
    },
  },
  methods: {
    path(locale) {
      return this.currentPathData.find(item => item.locale === locale).path
    },
    checkCurrentLocale(locale) {
      return this.locale === locale
    },
    toggleLanguagesList(value) {
      this.showLanguageList = value
    },
    clickedOutside() {
      this.toggleLanguagesList(false)
    },
  },
  created() {
    this.availableLocalesData = JSON.parse(this.availableLocales)
    this.currentPathData = JSON.parse(this.currentPath)
  },
}
</script>
