<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="container">
        <div class="row">
          <div class="col-24 d-flex d-md-block justify-content-center">
            <footer-contacts :phones="footerPhone" :email="footerEmail" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer__main">
      <div class="container">
        <div class="row">
          <div class="col-24 col-md-16 col-lg-12 mb-3 mb-md-0">
            <footer-navigation :footer-menu="footerMenuData" />
          </div>
          <div class="col-24 col-md-8 d-flex d-lg-none justify-content-start justify-content-md-end">
            <div class="footer__partners">
              <div class="footer__partner-item">
                <img :src="require('@javascript/packs/images/pci-logo.png')" alt="pci-dss" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-3">
      <vl-information-static-block :text="renderUserTokenAndTextCallCenter" />
    </div>
    <div class="footer__bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-24 col-lg-12">
            <p class="footer__copyright">&copy; Copyright {{renderCurrentYear}}</p>
          </div>
          <div class="col-24 col-lg-12 d-none d-lg-flex justify-content-end">
            <div class="footer__partners">
              <div class="footer__partner-item">
                <img :src="require('@javascript/packs/images/pci-logo.png')" alt="pci-dss" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterNavigation from './footer-navigation'
import FooterContacts from './footer-contacts'
import VlInformationStaticBlock from '@components/@exim/ui/vl-information-static-block'
import locales from './locales'
export default {
  name: 'Footer',
  components: {
    FooterNavigation,
    FooterContacts,
    VlInformationStaticBlock,
  },
  props: {
    footerMenu: {
      type: String,
      default: null,
    },
    footerCopyright: {
      type: String,
      default: null,
    },
    footerPhone: {
      type: Array,
      default: [],
    },
    footerEmail: {
      type: String,
      default: null,
    },
    userToken: {
      type: String,
      require: true,
    },
  },
  inject: ['locale'],
  data: () => ({
    t: locales,
    footerMenuData: null,
  }),
  computed: {
    renderUserTokenAndTextCallCenter() {
      return `${this.t[locale]['footer.information_about_callcenter']} ${this.userToken}`
    },
    renderCurrentYear() {
      return new Date().getFullYear()
    },
  },
  created() {
    this.footerMenuData = JSON.parse(this.footerMenu)
  },
}
</script>
