<template>
  <div class="header-bottom">
    <div class="container">
      <div class="row justify-content-between flex-nowrap no-gutters">
        <div class="header__wrapper-logo col-auto pr-0 pr-md-2">
          <a :href="baseUrlWithLocale" rel="home" class="header__wrapper-logo--site-logo">
            <img
              class="d-inline-block"
              :src="require('@javascript/packs/images/@exim/exim-logo.svg')"
              alt="EximBank"
            />
          </a>
        </div>
        <div class="d-flex align-items-center no-gutters">
          <template v-if="isLogin">
            <div class="col-auto d-flex align-items-center wrapper-nav">
              <div class="d-flex flex-md-row align-items-center">
                <a class="catalog-link" :href="catalogRouter">{{
                  t[locale]['exim.header.catalog']
                }}</a>
                <div
                  class="header__user-balance header__user-balance--desktop user-balance"
                >
                  <div class="user-balance__text mb-md-0">
                    {{ t[locale]['exim.header.your_balance'] }}
                  </div>
                  <div class="user-balance__amount">{{ balance }}</div>
                </div>
                <cart
                  v-if="!isOrderPage"
                  :locale="locale"
                  :default-locale="defaultLocale"
                  :user-id="userData.id"
                  :base-url-with-locale="baseUrlWithLocale"
                />
              </div>
            </div>
          </template>
          <!-- desktop wrapper -->
          <div v-if="!isLoginPage" class="col-auto d-none d-md-block">
            <vl-button
              class="h-100 header__login-button header__login-button--is-login"
              size="small"
              v-if="isLogin"
              @click.native.stop="toggleLoginBar"
            >
              <i class="icon-avatar">
                <vl-icon-base
                  iconName="avatar"
                  width="29"
                  height="29"
                  viewBoxWidth="29"
                  viewBoxHeight="29"
                >
                  <vl-icon-avatar />
                </vl-icon-base>
              </i>
              <span class="header__user-greeting">
                {{ t[locale]['header.greeting'] }}
                <span class="d-none d-lg-inline-block">, {{ userName }}</span>
              </span>
            </vl-button>
            <vl-button
              v-else
              class="h-100 header__login-button"
              size="small"
              @click.native.stop="toggleLoginForm"
            >
              <i class="icon-avatar">
                <vl-icon-base
                  iconName="avatar"
                  width="29"
                  height="29"
                  viewBoxWidth="29"
                  viewBoxHeight="29"
                >
                  <vl-icon-avatar />
                </vl-icon-base>
              </i>
              <span>{{ t[locale]['header.signin'] }}</span>
            </vl-button>
            <popap
              v-show="isShowLoginForm"
              @clickOutside="closeModal('isShowLoginForm')"
            >
              <div class="px-3">
                <login-form
                  :providerId="providerId"
                  :locale="locale"
                  :defaultLocale="defaultLocale"
                />
              </div>
            </popap>
            <popap
              v-if="isShowLoginBar"
              @clickOutside="closeModal('isShowLoginBar')"
            >
              <ul class="header-context-menu">
                <li
                  v-for="item of profileMenu"
                  :key="item.id"
                  class="header-context-menu__item"
                >
                  <a class="header-context-menu__url" :href="item.url">{{
                    item.title
                  }}</a>
                </li>
<!--                <li class="header-context-menu__item">-->
<!--                  <span class="header-context-menu__url" @click="API_LOGOUT">-->
<!--                    {{ t[locale]['authorization.logout.exit'] }}-->
<!--                  </span>-->
<!--                </li>-->

                <li class="header-context-menu__item">
                  <a
                    class="header-context-menu__url"
                    :href="authorizationLoginRouter"
                    data-method="delete"
                  >{{ t[locale]['authorization.logout.exit'] }}</a
                  >
                </li>
              </ul>
            </popap>
          </div>
          <!-- #desktop wrapper -->
          <!-- mobile wrapper -->
          <div v-if="!isLoginPage" class="col-auto d-md-none">
            <template v-if="isLogin">
              <div class="header__mobile-menu-btn" @click="toggleMobileMenu">
                <span></span>
              </div>
            </template>
            <template v-else>
              <vl-button
                class="h-100 header__login-button"
                size="small"
                @click.native="toggleMobileMenu"
              >
                <i class="icon-avatar">
                  <vl-icon-base
                    iconName="avatar"
                    width="29"
                    height="29"
                    viewBoxWidth="29"
                    viewBoxHeight="29"
                  >
                    <vl-icon-avatar />
                  </vl-icon-base>
                </i>
                <span>{{ t[locale]['header.signin'] }}</span>
              </vl-button>
            </template>
          </div>
          <!-- #mobile wrapper -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import Cart from './cart/Cart'
import LoginForm from './login-form'
import Popap from './popap'
import VlButton from '@components/@exim/ui/vl-button'
import VlIconBase from '@components/@exim/ui/vl-icons'
import VlIconBasket from '@components/@exim/ui/vl-icons/VlIconBasket'
import VlIconAvatar from '@components/@exim/ui/vl-icons/VlIconAvatar'
import locales from './locales'
import {
  CATALOG,
  ORDER_SHOW,
  AUTHORIZATION_LOGIN,
  AUTHORIZATION_LOGOUT,
} from '@javascript/config/routes'

export default {
  name: 'HeaderBottom',
  components: {
    Cart,
    LoginForm,
    Popap,
    VlButton,
    VlIconBase,
    VlIconBasket,
    VlIconAvatar,
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: null,
    },
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    providerId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    t: locales,
    isShowLoginBar: false,
    isShowMobileMenu: false,
    isShowLoginForm: false,
  }),
  computed: {
    catalogRouter() {
      return `${this.baseUrlWithLocale}${CATALOG}`
    },
    isOrderPage() {
      const orderRouter = `${this.baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    balance() {
      return this.userData
        ? `${this.userData.balance} ${this.userData.site_currency}`
        : ''
    },
    isLoginPage() {
      const loginRouter = `${this.baseUrlWithLocale}${AUTHORIZATION_LOGIN}`
      return window.location.pathname === loginRouter
    },
    authorizationLoginRouter() {
      return `${this.baseUrlWithLocale}${AUTHORIZATION_LOGOUT}`
    },
  },
  methods: {
    ...mapActions({
      API_LOGOUT: 'authorization/API_LOGOUT',
    }),
    toggleLoginBar() {
      this.isShowLoginBar = !this.isShowLoginBar
    },
    toggleMobileMenu() {
      this.$emit('click', !this.isShowMobileMenu)
    },
    toggleLoginForm() {
      this.isShowLoginForm = !this.isShowLoginForm
    },
    closeModal(modalName) {
      this[modalName] = false
    },
  },
}
</script>
